<div *ngIf="!isTransactionStopped; else thankYouTemplate">
  <div *ngIf="!transactionId; else transactionDetailsTemplate" class="main-content flex-grow-1 margin-no-transaction">
    <div>
      <mat-grid-list cols="3" rowHeight="1.5:1">
        <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
        <mat-grid-tile class="title1">{{'charging.charging' | translate}}</mat-grid-tile>
        <mat-grid-tile>
          <div class="example-button-row">
            <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="1" rowHeight="5:2">
        <mat-grid-list cols="1" rowHeight="24:3">
          <br>
        </mat-grid-list>
        <mat-grid-tile>
          <button class="start" mat-flat-button color="primary" [disabled]="transactoinStarted"
            (click)="startTransaction()">
            <ng-container *ngIf="!transactoinStarted">
              <ng-container *ngIf="!transactoinStarted">
                {{ 'charging.start' | translate }}
              </ng-container>
              <ng-container *ngIf="transactoinStarted">
                <mat-spinner diameter="24"></mat-spinner>
              </ng-container>
            </ng-container>
          </button>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="2" rowHeight="4:2">
        <mat-grid-list cols="3" rowHeight="1:1">
          <mat-grid-tile style="display: contents;">
            <div class="details-container">
              <span class="text-item title1">{{'charging.price' | translate}}:</span>
              <span class="text-item">0</span>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-tile>
          <div class="details-container" style="width: 85%;">
            <span class="text-item title1">{{'charging.delivered' | translate}}:</span>
            <span class="text-item">0</span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-container" style="width: 85%;">
            <span class="text-item title1">{{'charging.duration' | translate}}:</span>
            <span class="text-item">0</span>
          </div>
        </mat-grid-tile>
        
      </mat-grid-list>

      <mat-grid-list cols="1" rowHeight="24:3" class="backColor" style="text-wrap: nowrap; margin-top: 30px;">
        <mat-grid-tile class="title1">{{'charging.charging_Details' | translate}}</mat-grid-tile>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">Id: </span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">{{'qrcode.charging_Station' | translate}}: <span
                style="color: #B39DDB;">{{chargingStationId}}</span></span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">{{'qrcode.connector' | translate}}: <span
                style="color: #B39DDB;">{{connectorId}}</span></span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">{{'charging.location' | translate}}: <span style="color: #B39DDB;">{{
                chargingStation.site.address.address1 }}</span></span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" rowHeight="24:3">
        <br>
      </mat-grid-list>
    </div>
  </div>

  <!-- Show transaction details if available -->
  <div class="main-content flex-grow-1 margin-yes-transaction" *ngIf="transactionId">
    <div>
      <div class="starsec"></div>
      <div class="starthird"></div>
      <div class="starfourth"></div>
      <div class="starfifth"></div>
    </div>
    <mat-grid-list cols="3" rowHeight="1.5:1">
      <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
      <mat-grid-tile class="title1">{{'charging.charging' | translate}}</mat-grid-tile>
      <mat-grid-tile>
        <div class="example-button-row">
          <button class="help" mat-flat-button (click)="redirectToEv24()">Help</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="1" rowHeight="6:2">
      <mat-grid-tile>
        <button class="stop" mat-flat-button
          (click)="stopTransaction(dialogService, translateService)">{{'charging.stop' | translate}}</button>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="4:2">
      <mat-grid-list cols="3" rowHeight="1:1">
        <mat-grid-tile style="display: contents;">
          <div class="details-container-price">
            <span class="text-item title1">{{'charging.price' | translate}}:</span>
            <span class="text-item">{{transactionDetails.currentCumulatedRoundedPrice}}
              {{transactionDetails.priceUnit}}</span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-tile>
        <div class="details-container" style="width: 85%;">
          <span class="text-item title1">{{'charging.delivered' | translate}}:</span>
          <span class="text-item">{{energyInKWh}} kW.h</span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="details-container" style="width: 85%;">
          <span class="text-item title1">{{'charging.duration' | translate}}:</span>
          <span class="text-item">{{formattedDuration}}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Additional details go here -->
    <div>
      <mat-grid-list cols="1" rowHeight="21:3" class="backColor" style="text-wrap: nowrap; margin-top: 30px;">
        <mat-grid-list cols="1" rowHeight="6:1">
          <mat-grid-tile class="title1">{{'charging.charging_Details' | translate}}</mat-grid-tile>
        </mat-grid-list>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">Id: <span style="color: #B39DDB;">{{transactionDetails.id}}</span>
              <button mat-button style="max-width: 0px; padding: 0px"
                (click)="copyToClipboard(transactionDetails.id)"><mat-icon>content_copy</mat-icon></button>
            </span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">{{'qrcode.charging_Station' | translate}}: <span
                style="color: #B39DDB;">{{transactionDetails.chargeBoxID}}</span></span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">{{'qrcode.connector' | translate}}: <span
                style="color: #B39DDB;">{{transactionDetails.connectorId}}</span></span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-charging" style="width: 85%;">
            <span class="text-item title1">{{'charging.location' | translate}}: <span style="color: #B39DDB;">{{
                chargingStation.site.address.address1 }}</span></span>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <mat-grid-list cols="1" rowHeight="24:3">
    <br>
  </mat-grid-list>
</div>

<ng-template #thankYouTemplate>
  <div class="thank-you-container">
    <div class="thank-you-card">
      <h2 class="thank-you-title">{{'charging.thankyou' | translate}}</h2>
      <p class="thank-you-text">
        {{'charging.sessioFinished' | translate}}
      </p>
      <a [disabled]="!invoiceUrl" class="invoice-button" (click)="ViewInvoice()">
        {{isLoadingInvoice ? ('charging.chargement' | translate) : ('charging.ViewInvoice' | translate)}}
      </a>
    </div>
  </div>
</ng-template>