/* eslint-disable @typescript-eslint/no-floating-promises */

import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthorizationService } from 'services/authorization.service';
import { CentralServerService } from 'services/central-server.service';
import { LanguageService } from 'services/language.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { WindowService } from 'services/window.service';
import { AbstractTabComponent } from 'shared/component/abstract-tab/abstract-tab.component';
import { ChargePointStatus, ChargingStation, Connector } from 'types/ChargingStation';
import { ActionResponseQr } from 'types/DataResult';
import { RestResponse } from 'types/GlobalType';
import { UserNotifications, UserRole, UserStatus, UserWithPassword } from 'types/User';
import { Utils } from 'utils/Utils';

@Component({
  selector: 'app-qrcode',
  templateUrl: 'qrcode.component.html',
  styleUrls: ['qrcode.component.scss'],
})
export class QrCodeComponent extends AbstractTabComponent implements OnInit, OnDestroy {

  public canListTags: boolean;
  public pricingDefinitions: any | null = null;
  public chargingStation: ChargingStation | null = null;
  public connectorId: number;
  public connectorExists = true;
  public connectorStatus: string | null = null;
  public browserLanguage: string;
  public connectorStatusColor: string;
  public chargingStationId: string;
  public decodedHeader: any;
  public connectorTest: Connector;
  public decodedPayload: any;
  public userName: string;
  public tenantName: string;
  Sessiontoken: any;
  cardForm: FormGroup;
  intervalId: any;
  userRole: UserRole;
  isLoggedIn = false;
  userData: UserWithPassword;
  displayedColumns: string[] = [];
  token: any;
  createUserResponse: ActionResponseQr;
  dataLoaded = false;

  public constructor(
    activatedRoute: ActivatedRoute,
    windowService: WindowService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService,
  ) {
    super(activatedRoute, windowService, ['all']);
  }

  // Getters
  get hasFlatFee(): boolean {
    return this.pricingDefinitions?.dimensions?.flatFee?.active === true && this.pricingDefinitions?.dimensions?.flatFee?.price != null;
  }

  get hasEnergy(): boolean {
    return this.pricingDefinitions?.dimensions?.energy?.active === true && this.pricingDefinitions?.dimensions?.energy?.price != null;
  }

  get hasChargingTime(): boolean {
    return this.pricingDefinitions?.dimensions?.chargingTime?.active === true && this.pricingDefinitions?.dimensions?.chargingTime?.price != null;
  }

  get hasParkingTime(): boolean {
    return this.pricingDefinitions?.dimensions?.parkingTime?.active === true && this.pricingDefinitions?.dimensions?.parkingTime?.price != null;
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  redirectToPayment(): void {
    const token = Utils.decodeTokenHeader(this.token);
    if (token.chargingStationID && token.connectorID && this.connectorStatus === 'PREPARING') {
      this.onSubmit().then(() => {
        if (this.isLoggedIn === true) {
          this.router.navigate([`/qrcode/${this.token}/payment`]);
          this.spinnerService.hide();
        } else {
          console.log('No user');
        }
      });
    } else {
      const ConnectorStatusCheck = this.connectorTest.status;
      switch (true) {
        case !token.chargingStationID:
          this.messageService.showWarningMessage('Charging Station ID is missing.');
          break;
        case !token.connectorID:
          this.messageService.showWarningMessage('Connector ID is missing.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.CHARGING:
          this.messageService.showWarningMessage('Connector is already charging.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.UNAVAILABLE:
          this.messageService.showWarningMessage('Connector is Unavailable.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.OCCUPIED:
          this.messageService.showWarningMessage('Connector is Occupied.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.SUSPENDED_EVSE:
          this.messageService.showWarningMessage('EVse suspended.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.FAULTED:
          this.messageService.showWarningMessage('Connector is Faulted.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.RESERVED:
          this.messageService.showWarningMessage('Connector us Reserved.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.FINISHING:
          this.messageService.showWarningMessage('Please unplug the connector.');
          break;
        case ConnectorStatusCheck === ChargePointStatus.SUSPENDED_EV:
          this.messageService.showWarningMessage('EV Suspended');
          break;
        default:
          this.messageService.showWarningMessage('Connector must be plugged in your vehicle.');
      }
    }
  }

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.clearLocalStorage();
    this.chargingStation = null;
    this.pricingDefinitions = null;
    this.createForm();
    this.route.paramMap.subscribe((params) => {
      this.token = params.get('token');
    });
    localStorage.setItem('SessionToken', this.token);
    setTimeout(() => {
      this.checkLoginAndLoadData();
    }, 2000);
  }

  createForm() {
    this.cardForm = this.fb.group({
      email: ['', Validators.email],
    });
  }

  public createAllTrueNotifications = (): UserNotifications => ({
    sendSessionStarted: true,
    sendOptimalChargeReached: true,
    sendEndOfCharge: true,
    sendEndOfSession: true,
    sendUserAccountStatusChanged: true,
    sendNewRegisteredUser: true,
    sendUnknownUserBadged: true,
    sendChargingStationStatusError: true,
    sendChargingStationRegistered: true,
    sendOcpiPatchStatusError: true,
    sendOicpPatchStatusError: true,
    sendUserAccountInactivity: true,
    sendPreparingSessionNotStarted: true,
    sendOfflineChargingStations: true,
    sendBillingSynchronizationFailed: true,
    sendBillingPeriodicOperationFailed: true,
    sendSessionNotStarted: true,
    sendCarCatalogSynchronizationFailed: true,
    sendComputeAndApplyChargingProfilesFailed: true,
    sendEndUserErrorNotification: true,
    sendBillingNewInvoice: true,
    sendAdminAccountVerificationNotification: true,
  });

  public async onSubmit(): Promise<void> {
    try {
      let email = this.cardForm.get('email').value;
      let userCreatedOrLoggedIn = false;
      this.userName = Utils.getUsernameFromEmail(email);
      const DEFAULT_PASSWORD = `${this.userName}@123A`;
      this.spinnerService.hide();
      // Scenario 1: No email  - Create new user with random email
      if (!email) {
        email = Utils.generateRandomEmail();
        this.userName = Utils.getUsernameFromEmail(email);
        await this.createNewUser(email, DEFAULT_PASSWORD);
        console.log('QrCodeComponent ~ onSubmit ~ Scenario 1:');
        userCreatedOrLoggedIn = true;
        this.spinnerService.hide();
        return;
      }

      // Scenario 2 & 3: Email exists - Try login then create with incremented email
      try {
        // Try to create user first
        await this.createNewUser(email, DEFAULT_PASSWORD);
        console.log('QrCodeComponent ~ onSubmit ~ Scenario 2:');
        userCreatedOrLoggedIn = true;
        this.spinnerService.hide();
      } catch (error: any) {
        const errorCode = error?.details?.errorCode || error?.error?.errorCode;
        this.spinnerService.hide();
        if (errorCode === 510) {
          try {
            // Scenario 2: Try logging in with default password
            await this.loginUser(email, DEFAULT_PASSWORD);
            console.log('QrCodeComponent ~ onSubmit ~ Scenario 3:');
            userCreatedOrLoggedIn = true;
            this.spinnerService.hide();
          } catch (loginError) {
            // Scenario 3: Login failed, create new user with email+
            const emailParts = email.split('@');
            const newEmail = `${emailParts[0]}+${this.getCurrentTimeForEmail()}@${emailParts[1]}`;
            console.log('QrCodeComponent ~ onSubmit ~ Scenario 4:');
            await this.createNewUser(newEmail, DEFAULT_PASSWORD);
            userCreatedOrLoggedIn = true;
            this.spinnerService.hide();
          }
          this.spinnerService.hide();
        } else {
          throw error;
        }
      }
    } catch (error) {
      this.messageService.showErrorMessage('An unexpected error occurred');
      console.error('Error during authentication flow:', error);
      this.spinnerService.hide();
    }
    this.spinnerService.hide();
  }

  public getChargingStationTariffID() {
    const ChargingStationID = this.chargingStation.id;
    const ConnectorID = this.connectorId;
    const roaming = false;

    this.centralServerService.getChargingStationTariffQr(ChargingStationID, ConnectorID, roaming).subscribe({
      next: (data) => {
        this.pricingDefinitions = data;
        console.log('Tariff Data:', this.pricingDefinitions);
      },
      error: (err) => {
        console.error('Error fetching tariff data:', err);
      },
    });
  }


  private async createNewUser(email: string, password: string): Promise<void> {
    this.spinnerService.show();
    this.userData = {
      status: UserStatus.ACTIVE,
      firstName: this.chargingStation.id,
      name: this.userName,
      email,
      locale: 'en_US',
      role: UserRole.QRCODE,
      id: '',
      issuer: false,
      fullName: '',
      tags: [],
      plateID: '',
      phone: undefined,
      mobile: '',
      notificationsActive: true,
      notifications: this.createAllTrueNotifications(),
      address: undefined,
      iNumber: '',
      costCenter: undefined,
      image: '',
      language: '',
      numberOfSites: 0,
      scopes: [],
      companies: [],
      sites: [],
      sitesAdmin: [],
      sitesOwner: [],
      userHashID: 0,
      tenantHashID: 0,
      eulaAcceptedHash: '',
      eulaAcceptedVersion: 0,
      eulaAcceptedOn: undefined,
      billingData: undefined,
      technical: false,
      freeAccess: false,
      password,
      acceptEula: true,
    };
    this.createUserResponse = await firstValueFrom(this.centralServerService.createUserQr(this.userData));
    this.spinnerService.hide();
    if (this.createUserResponse.status === RestResponse.SUCCESS) {
      console.log(`User created successfully with email: ${email}`);
      await this.loginUser(email, password);
      const createdUserId = this.createUserResponse.id;
      this.userData.id = createdUserId;
      localStorage.setItem('userId', createdUserId);
      this.spinnerService.hide();
    }

    this.spinnerService.hide();
  }

  private async loginUser(email: string, password: string): Promise<void> {
    this.spinnerService.show();
    this.authorizationService.cleanUserAndUserAuthorization();
    const loginUser: UserWithPassword = {
      email,
      password,
      id: '',
      issuer: false,
      name: '',
      firstName: '',
      fullName: '',
      tags: [],
      plateID: '',
      phone: undefined,
      mobile: '',
      notificationsActive: false,
      notifications: this.createAllTrueNotifications(),
      address: undefined,
      iNumber: '',
      costCenter: false,
      status: UserStatus.ACTIVE,
      image: '',
      role: UserRole.QRCODE,
      locale: '',
      language: '',
      numberOfSites: 0,
      scopes: [],
      companies: [],
      sites: [],
      sitesAdmin: [],
      sitesOwner: [],
      userHashID: 0,
      tenantHashID: 0,
      eulaAcceptedHash: '',
      eulaAcceptedVersion: 0,
      eulaAcceptedOn: undefined,
      billingData: undefined,
      technical: false,
      freeAccess: false,
      acceptEula: true,
    };

    const result = await firstValueFrom(this.centralServerService.login(loginUser));
    this.isLoggedIn = true;
    this.centralServerService.loginSucceeded(result.token);
    const loggedUser = this.centralServerService.getLoggedUser();
    localStorage.setItem('userId', loggedUser.id);
    this.spinnerService.hide();
  }

  private getCurrentTimeForEmail(): string {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${hours}${minutes}${seconds}`;
  }

  private checkLoginAndLoadData(): void {
    this.Sessiontoken = Utils.decodeTokenHeader(this.token);
    console.log('QrCodeComponent ~ checkLoginAndLoadData ~ token:', this.Sessiontoken);
    this.authorizationService.getUserRole();
    this.setupLanguagePreferences();
    const entityId = this.Sessiontoken.chargingStationID;
    const connectorId = Number(this.Sessiontoken.connectorID);

    if (entityId) {
      this.loadChargingStation(entityId, connectorId).subscribe({
        next: (chargingStation: ChargingStation) => {
          this.getChargingStationTariffID();
          if (connectorId) {
            this.intervalId = setInterval(() => {
              this.loadChargingStation(entityId, connectorId).subscribe();
            }, 5000);
          } else {
            console.error('Connector ID is missing in the URL');
          }
        },
        error: (error) => {
          console.error('Failed to load charging station:', error);
        },
      });
    } else {
      console.error('Entity ID is missing in the URL');
    }
  }

  private loadChargingStation(entityId: string, connectorId: number): Observable<ChargingStation> {
    return new Observable((observer) => {
      this.centralServerService.getChargingStationQr(entityId).subscribe({
        next: (chargingStation: ChargingStation) => {
          this.chargingStation = chargingStation;
          const chargingStationData = JSON.stringify(this.chargingStation);
          localStorage.setItem('chargingStation', chargingStationData);

          this.connectorExists = chargingStation.connectors.some(
            (connector) => connector.connectorId === connectorId
          );
          this.connectorId = connectorId;
          this.connectorTest = Utils.getConnectorFromID(chargingStation, connectorId);

          if (this.connectorTest) {
            const statusKey = `connectorStatus.${this.connectorTest.status.toUpperCase()}`;
            this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
              this.connectorStatus = translatedStatus || this.connectorTest.status;
              this.connectorStatusColor = Utils.applyStatusColor(this.connectorTest.status.toUpperCase());
              this.connectorExists = true;
              this.dataLoaded = true;
              observer.next(chargingStation);
              observer.complete();
            });
          } else {
            this.connectorExists = false;
            this.connectorStatus = '';
            this.dataLoaded = false;
            observer.error('Connector does not exist');
          }

          if (!this.connectorExists) {
            this.spinnerService.hide();
          }
        },
        error: (error) => {
          console.error('Failed to load charging station:', error);
          this.spinnerService.hide();
          this.connectorExists = false;
          this.connectorStatus = '';
          this.dataLoaded = false;
          observer.error(error);
        },
      });
    });
  }

  private setupLanguagePreferences(): void {
    this.browserLanguage = this.languageService.getBrowserLanguage() || 'fr';
    localStorage.setItem('language', this.browserLanguage);
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = this.browserLanguage.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);
  }

  private clearLocalStorage(): void {
    const transactionId = localStorage.getItem('transactionId');
    const userId = localStorage.getItem('userId');
    const tagId = localStorage.getItem('tagId');

    if (transactionId && tagId && userId) {
      this.centralServerService.getTransactionQr(Number(transactionId)).subscribe({
        next: (transaction) => {
          const transactionDetails: any = transaction;
          console.log('ChargingComponent ~ this.centralServerService.getTransaction ~ this.transactionDetails:', transactionDetails);
          if (transactionDetails.stop !== null && Object.keys(transaction.stop).length > 0) {
            this.clearStorageForTransaction();
          } else {
            if (transactionDetails.userID === userId) {
              this.router.navigate([`/qrcode/${this.token}/charging`]);
            } else {
              console.log('-------else------------:',);
            }
          }
        },
        error: (error) => {
          console.error('Error fetching transaction details:', error);
        }
      });
    }
  }

  private clearStorageForTransaction(): void {
    const keys =[
      'SessionToken',
      'transactionId',
      'userId',
      'tagId',
      'chargingStation',
      'token',
    ];

    keys.forEach(key => localStorage.removeItem(key));
  }

}
